import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, IUser } from "../interfaces";

interface ProductService {
  createShop: (data: {}) => Promise<IAPIResponse>;
  updateShop: (data: {}, id: string) => Promise<IAPIResponse>;
  getAllBrandsList: () => Promise<IAPIResponse<any[]>>;
  listAllStores: (
    page: number,
    limit: number,
    search: string
  ) => Promise<IAPIResponse<any[]>>;
  listInboundProducts: (
    page: number,
    limit: number,
    search: string
  ) => Promise<IAPIResponse<INBOUND>>;
  deleteShop: (id: string) => Promise<IAPIResponse>;
  acceptInboundRequest: (id: string) => Promise<IAPIResponse>;
  rejectInboundRequest: (id: string, reason: string) => Promise<IAPIResponse>;
  getSingle: (id: string) => Promise<IAPIResponse<any | null>>;
  addImage: (data: {}) => Promise<IAPIResponse<any | null>>;
}

interface INBOUNDPRODUCT {
  products: any[];
  totalCount: number;
}
interface INBOUND {
  message: string;
  status: number;
  data: INBOUNDPRODUCT;
  products: any[];
  totalCount: number;
}

const storeService = (httpClient: AxiosInstance): ProductService => {
  return {
    createShop: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/add/new/store",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Brand creation failed" };
      }
    },
    getAllBrandsList: async (): Promise<IAPIResponse<any[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any[]>> = await httpClient.get(
          "/get/all/brands/admin"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch brands" };
      }
    },
    listAllStores: async (
      page,
      limit,
      search = ""
    ): Promise<IAPIResponse<any[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any[]>> = await httpClient.get(
          `/list/all/shops/admin?page=${page}&limit=${limit}&search=${search}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch stores" };
      }
    },
    deleteShop: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/delete/shop/",
          {
            storeId: id,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Shop deletion failed" };
      }
    },
    getSingle: async (id): Promise<IAPIResponse<any | null>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser | null>> =
          await httpClient.post("/get/single/shop/admin", { storeId: id });
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch store" };
      }
    },
    updateShop: async (data, id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/update/regenf/shop/",
          {
            storeId: id,
            data,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Brand update failed" };
      }
    },
    addImage: async (data) => {
      try {
        const res = await httpClient.post("/add/shop/image/", data);
        return res.data;
      } catch (err) {
        console.log("err", err);
        return { status: false, message: "File upload failed" };
      }
    },
    listInboundProducts: async (
      page,
      limit,
      search = ""
    ): Promise<IAPIResponse<INBOUND>> => {
      try {
        const res: AxiosResponse<IAPIResponse<INBOUND>> = await httpClient.get(
          `get/sold/products/regenf/admin?page=${page}&limit=${limit}&search=${search}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch stores" };
      }
    },
    acceptInboundRequest: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/accept/product/regenf/",
          {
            productId: id,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Shop deletion failed" };
      }
    },
    rejectInboundRequest: async (id, reason): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/reject/product/regenf/",
          {
            productId: id,
            reason,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Shop deletion failed" };
      }
    },
  };
};

export default storeService;
