import { AxiosInstance, AxiosResponse } from "axios";
import {
  IAPIResponse,
  IAdminCount,
  IUser,
  IUserCount,
} from "../interfaces";

interface UserService {
  createUser: (data: {}) => Promise<IAPIResponse>;
  createAdmin: (data: {}) => Promise<IAPIResponse>;
  createSuperAdmin: (data: {}) => Promise<IAPIResponse>;
  updateUser: (data: {}, id: string) => Promise<IAPIResponse>;
  updateUserAdmin: (data: {}, id: string) => Promise<IAPIResponse>;
  getUsers: () => Promise<IAPIResponse<IUser[]>>;
  getSingleUser: (id: string) => Promise<IAPIResponse<IUser>>;
  getAllUsers: (
    page: number,
    limit: number,
    search: string,
    verified: string
  ) => Promise<IAPIResponse<IUserCount>>;
  getAllAdmins: (
    page: number,
    limit: number
  ) => Promise<IAPIResponse<IAdminCount>>;
  getAllSuperAdmins: (
    page: number,
    limit: number
  ) => Promise<IAPIResponse<IAdminCount>>;
  // getAdmins: () => Promise<IAPIResponse<IUser[]>>;
  getSuperAdmins: () => Promise<IAPIResponse<IUser[]>>;
  deleteUser: (id: string) => Promise<IAPIResponse>;
  getSingle: (id: string) => Promise<IAPIResponse<IUser | null>>;
}

const userService = (httpClient: AxiosInstance): UserService => {
  return {
    createUser: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/user/create",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User creation failed" };
      }
    },
    createAdmin: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/add/new/admin",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Admin creation failed" };
      }
    },
    createSuperAdmin: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/add/new/super/admin",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "SuperAdmin creation failed" };
      }
    },
    getUsers: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/users"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    getSingleUser: async (id: string): Promise<IAPIResponse<IUser>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser>> = await httpClient.post(
          "/get/single/admin",
          { userId: id }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch user" };
      }
    },
    getAllUsers: async (
      page: number,
      limit: number,
      search: string,
      verified: string
    ): Promise<IAPIResponse<IUserCount>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUserCount>> = await httpClient.get(
          `/get/all/users?page=${page}&limit=${limit}&search=${search ? search : ""}&verified=${verified}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    getAllAdmins: async (page, limit): Promise<IAPIResponse<IAdminCount>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IAdminCount>> =
          await httpClient.get(`/get/all/admins?page=${page}&limit=${limit}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch admins" };
      }
    },
    getAllSuperAdmins: async (page, limit): Promise<IAPIResponse<IAdminCount>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IAdminCount>> =
          await httpClient.get(`/get/all/super/admins?page=${page}&limit=${limit}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch admins" };
      }
    },
    getSuperAdmins: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/super-admins"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    deleteUser: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/user/delete/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User deletion failed" };
      }
    },
    getSingle: async (id): Promise<IAPIResponse<IUser | null>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser | null>> =
          await httpClient.get(`/user/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch user" };
      }
    },
    updateUser: async (data, id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/user/update/${id}`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User update failed" };
      }
    },
    updateUserAdmin: async (data, id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/edit/admin/",
          {
            userId: id,
            data,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User update failed" };
      }
    },
  };
};

export default userService;
