const processENV = process.env;
const serverURL = processENV.REACT_APP_API_URL || "/api/";
const basePath = processENV.REACT_APP_BASE_URL || "/";
const s3Url = processENV.REACT_APP_S3_URL || "";
export const GOOGLE_MAP_KEY = process.env.GOOGLE_MAP_KEY || 'AIzaSyBb9csTf30yNDmqNOX1_MfUyv-3Jr9iF9g';

export const KEY_STRING = "X3YvtRPmZ819fxLQebnja";

export const apiConfig = {
  baseURL: `${serverURL}/api/app/`,
  serverURL,
  timeout: 300000,
  basePath,
  s3Url
};

export const IMAGE_COUNT = 4;
