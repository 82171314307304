import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, IProductList, IUser } from "../interfaces";

interface ProductService {
  addProduct: (data: {}) => Promise<IAPIResponse>;
  addImages: (data: {}) => Promise<IAPIResponse>;
  addVideos: (data: {}) => Promise<IAPIResponse>;
  acceptProductService: (id: string) => Promise<IAPIResponse>;
  rejectProductService: (id: string, data: string) => Promise<IAPIResponse>;
  updateProduct: (id: string, data: {}) => Promise<IAPIResponse>;
  listProductsRegenf: (page: number, limit: number) => Promise<IAPIResponse>;
  listAllProducts: (
    page: number,
    limit: number,
    searchQuery: string
  ) => Promise<IAPIResponse>;
  listUserActiveProducts: (
    userId: string,
    page: number,
    limit: number
  ) => Promise<IAPIResponse>;
  productListingService: (
    page: number,
    limit: number,
    searchQuery: string
  ) => Promise<IAPIResponse>;
  userProductListingService: (
    userId: string,
    page: number,
    limit: number
  ) => Promise<IAPIResponse>;
  donatedProductListingService: (
    page: number,
    limit: number,
    searchQuery: string
  ) => Promise<IAPIResponse>;
  listAllRegenfProducts: (
    page: number,
    limit: number,
    search: string
  ) => Promise<IAPIResponse>;
  listAllDonatedProducts: (
    page: number,
    limit: number,
    search: string
  ) => Promise<IAPIResponse>;
  getAllUsers: () => Promise<IAPIResponse<IUser[]>>;
  // getAdmins: () => Promise<IAPIResponse<IUser[]>>;
  getSuperAdmins: () => Promise<IAPIResponse<IUser[]>>;
  deleteProduct: (id: string) => Promise<IAPIResponse>;
  getSingleProduct: (id: string) => Promise<IAPIResponse<any | null>>;
  removeImageService: (img: string) => Promise<IAPIResponse>;
  removeVideoService: (img: string) => Promise<IAPIResponse>;
  toggleProductStatus: (productId: string) => Promise<IAPIResponse>;
}

const productService = (httpClient: AxiosInstance): ProductService => {
  return {
    addProduct: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/add/regenf/product/admin",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to add product" };
      }
    },
    addImages: async (data) => {
      try {
        const res = await httpClient.post("/upload/multi/product/images", data);
        return res.data;
      } catch (err) {
        console.log("err", err);
        return { status: false, message: "File upload failed" };
      }
    },
    addVideos: async (data) => {
      try {
        const res = await httpClient.post("/upload/multi/product/videos", data);
        console.log("Response\t\t", res);
        return res.data;
      } catch (err) {
        console.log("err", err);
        return { status: false, message: "File upload failed" };
      }
    },
    listProductsRegenf: async (page, limit): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          `/get/regenf/admin?page=${page}&limit=${limit}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch products" };
      }
    },
    listAllProducts: async (
      page,
      limit,
      searchQuery = ""
    ): Promise<IAPIResponse<IProductList[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IProductList[]>> =
          await httpClient.get(
            `/get/all/products/admin?page=${page}&limit=${limit}&search=${searchQuery}`
          );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch products" };
      }
    },
    listUserActiveProducts: async (
      userId,
      page,
      limit
    ): Promise<IAPIResponse<IProductList[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IProductList[]>> =
          await httpClient.post(
            `/get/user/active/products/admin?page=${page}&limit=${limit}`,
            { userId }
          );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch products" };
      }
    },
    listAllRegenfProducts: async (
      page,
      limit,
      search = ""
    ): Promise<IAPIResponse<IProductList[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IProductList[]>> =
          await httpClient.get(
            `/get/regenf/products/admin?page=${page}&limit=${limit}&search=${search}`
          );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch products" };
      }
    },
    listAllDonatedProducts: async (
      page,
      limit,
      search = ""
    ): Promise<IAPIResponse<IProductList[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IProductList[]>> =
          await httpClient.get(
            `/get/donated/products/admin?page=${page}&limit=${limit}&search=${search}`
          );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch products" };
      }
    },

    getAllUsers: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/all-users"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    getSuperAdmins: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/super-admins"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    deleteProduct: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/remove/product/admin",
          { productId: id }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User deletion failed" };
      }
    },
    // getSingleProduct: async (id): Promise<IAPIResponse<any | null>> => {
    //     try {
    //         const res: AxiosResponse<IAPIResponse<any | null>> =
    //             await httpClient.get(`/get/product/admin/${id}`);
    //         return res.data;
    //     } catch (error) {
    //         return { status: false, message: "Failed to fetch product" };
    //     }
    // },
    getSingleProduct: async (id): Promise<IAPIResponse<any | null>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any | null>> =
          await httpClient.post(`/get/product/admin/`, { productId: id });
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch product" };
      }
    },
    updateProduct: async (id, data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          `/edit/product/admin`,
          {
            productId: id,
            data,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Product update failed" };
      }
    },
    productListingService: async (
      page,
      limit,
      searchQuery = ""
    ): Promise<IAPIResponse<IProductList[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IProductList[]>> =
          await httpClient.get(
            `/get/product/listing/admin?page=${page}&limit=${limit}&search=${searchQuery}`
          );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch products" };
      }
    },
    userProductListingService: async (
      userId,
      page,
      limit
    ): Promise<IAPIResponse<IProductList[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IProductList[]>> =
          await httpClient.post(
            `/get/user/listing/admin?page=${page}&limit=${limit}`,
            { userId }
          );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch products" };
      }
    },
    donatedProductListingService: async (
      page,
      limit,
      searchQuery = ""
    ): Promise<IAPIResponse<IProductList[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IProductList[]>> =
          await httpClient.get(
            `/get/donated/product/listing/admin?page=${page}&limit=${limit}&searchQuery=${searchQuery}`
          );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch products" };
      }
    },
    acceptProductService: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/accept/new/product",
          {
            productId: id,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User update failed" };
      }
    },
    rejectProductService: async (id, data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/reject/new/product",
          {
            productId: id,
            data: data,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User update failed" };
      }
    },
    removeImageService: async (img): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/remove/product/image/admin/",
          {
            img,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Remove image failed" };
      }
    },
    removeVideoService: async (img): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/remove/product/video/admin/",
          {
            img,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Remove video failed" };
      }
    },
    toggleProductStatus: async (productId: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/toggle/active/inactive/",
          {
            productId,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to change product status" };
      }
    },
  };
};

export default productService;
