import { validateToken } from "../utils/auth-utils";
import { sideBarOptions, superAdminMenuItems, adminMenuItems, SUPER_ADMIN_ROLE, ADMIN_ROLE } from "../utils/constants";

export const addUserAccessRoles = ['admin'];

export const getSideBarOptions = () => {
  const { role } = validateToken();
  let options: any[] = [];
  switch (role) {
    case SUPER_ADMIN_ROLE:
      const sAdminAccessibleMenuOptions = sideBarOptions.filter(o => superAdminMenuItems.includes(o.name));
      options = sAdminAccessibleMenuOptions;
      break;
    case ADMIN_ROLE:
      const adminAccessibleMenuOptions = sideBarOptions.filter(o => adminMenuItems.includes(o.name));
      options = adminAccessibleMenuOptions;
      break;
    default:
      // const userAccessibleMenuOptions = sideBarOptions.filter(o => superAdminMenuItems.includes(o.name));
      // options = userAccessibleMenuOptions;
      break;
  }

  return options;
}