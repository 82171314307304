import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useState } from "react";

import EnhancedTable from "../../components/common/custom-table/CustomTable";
import { ADMIN_ROLE } from "../../utils/constants";
import { userService } from "../../service";
import { IAdminCount, IUser } from "../../interfaces";
import SubTopBar from "../../components/template/SubTopBar";
import Loader from "../../components/Loader";
import { Box, Button, Modal, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { AiOutlineClose } from "react-icons/ai";
import { TiTick } from "react-icons/ti";


const SuperAdmins = () => {
 

  // const [userCreation, setUserCreation] = useState(false);

  const [adminCreation, setAdminCreation] = useState(false);
  const [usersData, setUsersData] = useState<IAdminCount | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [trigger, setTrigger] = useState(false);
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const res = await userService.getAllSuperAdmins(page, limit);
      // console.log("ADMINS\t\t", res);
      if (!res.status || !res.data) {
        console.log(res.message);
        return;
      }
      setUsersData(res.data);
    } catch (err) {
      console.log("Error while fetching users\t", err);
    } finally {
      setLoading(false);
    }
  }, [limit, page]);

  useEffect(() => {
    fetchUsers();
  }, [page, limit, fetchUsers]);



  useEffect(() => {
    fetchUsers();
  }, [trigger]);

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
  });

  const resetFormData = () => {
    setFormData({
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
    });
  };

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Validate email
    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Invalid email address",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }

    // Validate password
    if (name === "password") {
      const passwordPattern =
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
      if (!passwordPattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password:
            "Password must be at least 6 characters long and contain atleast one uppercaser letter, one number, and one special character",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "",
        }));
      }
    }

    // Validate confirm password
    if (name === "confirmPassword") {
      if (value !== formData.password) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "Passwords do not match",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "",
        }));
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      errors.email.length > 0 ||
      errors.password.length > 0 ||
      errors.confirmPassword.length > 0
    ) {
      snackbar("Failed to submit", { variant: "error" });
      return;
    }
    if (formData.email?.length === 0) {
      snackbar("Email is missing", { variant: "error" });
      return;
    }
    if (formData.name?.length === 0) {
      snackbar("Name is missing", { variant: "error" });
      return;
    }
    if (formData.password?.length === 0) {
      snackbar("Password is missing", { variant: "error" });
      return;
    }
    if (
      formData.password?.length > 0 &&
      formData.confirmPassword?.length === 0
    ) {
      snackbar("Re-enter password", { variant: "error" });
      return;
    }

    console.log(formData);
    const response = await userService.createSuperAdmin(formData);
    if (!response || !response?.status) {
      snackbar(
        `${response?.message ? response.message : "Failed to add admin"}`,
        { variant: "error" }
      );
      return;
    }
    snackbar("Added new admin", { variant: "success" });
    setFormData({ email: "", name: "", password: "", confirmPassword: "" });
    setTrigger(!trigger);
    setAdminCreation(false);
    return;
  };

  const [editModal, openEditModal] = useState<boolean>(false);
  const handleSelectUser = async (id: string) => {
    //EDIT
    console.log("ROROROR\t\t", id);
    await fetchSingleUser(id);
  };

  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const fetchSingleUser = async (userId: string) => {
    try {
      setLoading(true);
      const res = await userService.getSingleUser(userId);
      console.log("Selected User\t\t", res);
      if (!res.status || !res.data) {
        snackbar("Failed to fetch user", { variant: "error" });
        return;
      }
      setSelectedUser(res.data);
    } catch (err) {
      console.log("Error fetching user\t", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      setFormData({
        email: selectedUser?.email ? selectedUser.email : "",
        name: selectedUser?.name ? selectedUser.name : "",
        password: "",
        confirmPassword: "",
      });
      openEditModal(true);
    }
  }, [selectedUser]);

  const handleUpdate = async (event, userId: string) => {
    try {
      setLoading(true);
      event.preventDefault();
      if (
        !formData.password ||
        formData.password.length <= 0 ||
        !formData.confirmPassword ||
        formData.confirmPassword.length <= 0
      ) {
        snackbar("Please fill the fields", { variant: "error" });
        return;
      }
      const updateData = {
        password: formData.password,
      };
      console.log("FormData_UPDATE\t", formData, "\n", updateData);
      const res = await userService.updateUserAdmin(updateData, userId);
      if (!res || !res.status) {
        console.log("Res\t", res);
        snackbar("Failed to update admin", { variant: "error" });
        return;
      }
      snackbar("Password has been updated.", { variant: "success" });
      return;
    } catch (err) {
      console.log("Error_updating\t", err);
      snackbar("Failed to update admin", { variant: "error" });
    } finally {
      openEditModal(false);
      setSelectedUser(null);
      resetFormData();
      setLoading(false);
      return;
    }
  };

  const [confirm, openConfirm] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<any | null>(null);
  const handleDeleteUser = async (id: string, name: string) => {
    //DELETE
    setDeleteUser({
      _id: id,
      name: name,
    });
  };

  useEffect(() => {
    if (deleteUser) {
      console.log("HI");
      openConfirm(true);
    }
  }, [deleteUser]);

  const handleAccept = async () => {
    try {
      setLoading(true);
      const updateData = {
        isRemoved: true,
      };
      console.log("FormData_UPDATE\t", formData, "\n", updateData);
      const res = await userService.updateUserAdmin(updateData, deleteUser._id);
      if (!res || !res.status) {
        console.log("Res\t", res);
        snackbar("Failed to delete admin", { variant: "error" });
        return;
      }
      snackbar("Admin has been removed.", { variant: "success" });
      return;
    } catch (err) {
      console.log("Error_Deleting\t\t", err);
    } finally {
      setDeleteUser(null);
      openConfirm(false);
      setTrigger(!trigger);
      setLoading(false);
    }
  };

  return (
    <>
      {
        <>
          <SubTopBar
            title="Super Admins"
            buttonIcon={<AddIcon />}
            buttonText="add super admin"
            onButtonClick={() => setAdminCreation(true)}
            isSearch={false}
          />
          <Box
            sx={{
              height: "calc(100% - 125px)",
              paddingBottom: "32px",
              paddingRight: "32px",
            }}
          >
            {loading ? (
              <Loader isResponsive />
            ) : (
              <>
                {usersData && usersData?.admins?.length ? (
                  <EnhancedTable
                    tableData={usersData.admins}
                    refresh={() => {
                      fetchUsers();
                    }}
                    role={ADMIN_ROLE}
                    rowsPerPage={limit}
                    page={page}
                    setRowsPerPage={(e) => setLimit(e)}
                    setPage={(e) => setPage(e)}
                    totalCount={usersData?.totalCount}
                    handleSelectUser={handleSelectUser}
                    handleDeleteUser={handleDeleteUser}
                  />
                ) : (
                  <Typography>No data</Typography>
                )}
              </>
            )}
          </Box>
          <Modal
            open={adminCreation}
            onClose={() => setAdminCreation(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "40vw",
                height: "auto",
                backgroundColor: "#ffffff",
                borderRadius: "0.5rem",
                padding: "1.5rem",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  textAlign: "center",
                  color: "green",
                  mb: "1.5rem",
                }}
              >
                ADD ADMIN
              </Typography>
              <Box>
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    required
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Password"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={!!errors.password}
                    helperText={errors.password}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Re-enter Password"
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    sx={{ marginBottom: 2 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      onClick={() => setAdminCreation(false)}
                      variant="contained"
                      type="submit"
                      sx={{
                        mt: 0,
                        ml: "10px",
                        background: "darkred",
                        borderRadius: "10px",
                        m: 0,
                        "&:hover": {
                          background:
                            "linear-gradient(45deg, #4EAF26, #6EBB2D, #8FC833)",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{
                        mt: 0,
                        ml: "10px",
                        background: "green",
                        borderRadius: "10px",
                        "&:hover": {
                          background:
                            "linear-gradient(45deg, #4EAF26, #6EBB2D, #8FC833)",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Modal>
          {selectedUser && formData && (
            <Modal
              open={editModal}
              onClose={() => {
                setSelectedUser(null);
                resetFormData();
                openEditModal(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "40vw",
                  height: "auto",
                  backgroundColor: "#ffffff",
                  borderRadius: "0.5rem",
                  padding: "1.5rem",
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    color: "green",
                    mb: "1.5rem",
                  }}
                >
                  CHANGE PASSWORD
                </Typography>
                <Box>
                  <form
                    onSubmit={(event) => handleUpdate(event, selectedUser._id)}
                  >
                    {/* <TextField
                      label="Email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      fullWidth
                      required
                      error={!!errors.email}
                      helperText={errors.email}
                      sx={{ marginBottom: 2 }}
                    />
                    <TextField
                      label="Name"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      fullWidth
                      required
                      sx={{ marginBottom: 2 }}
                    /> */}
                    <TextField
                      label="Password"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      fullWidth
                      required
                      error={!!errors.password}
                      helperText={errors.password}
                      sx={{ marginBottom: 2 }}
                    />
                    <TextField
                      label="Re-enter Password"
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      fullWidth
                      required
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword}
                      sx={{ marginBottom: 2 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setSelectedUser(null);
                          resetFormData();
                          openEditModal(false);
                        }}
                        variant="contained"
                        type="submit"
                        sx={{
                          mt: 0,
                          ml: "10px",
                          background: "darkred",
                          borderRadius: "10px",
                          m: 0,
                          "&:hover": {
                            background:
                              "linear-gradient(45deg, #4EAF26, #6EBB2D, #8FC833)",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={(event) =>
                          handleUpdate(event, selectedUser._id)
                        }
                        variant="contained"
                        sx={{
                          mt: 0,
                          ml: "10px",
                          background: "green",
                          borderRadius: "10px",
                          "&:hover": {
                            background:
                              "linear-gradient(45deg, #4EAF26, #6EBB2D, #8FC833)",
                          },
                        }}
                      >
                        Update
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Modal>
          )}
          {deleteUser && (
            <Modal open={confirm} onClose={() => openConfirm(false)}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30vw",
                  height: "auto",
                  backgroundColor: "white",
                  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.5)",
                  padding: 30,
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <AiOutlineClose
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                  fontSize: "1.2rem",
                }}
                onClick={() => openConfirm(false)}
              /> */}
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      mb: "2rem",
                    }}
                  >
                    Are you sure you want remove {deleteUser?.name} ?
                  </Typography>
                </Box>

                <Box sx={{ flexDirection: "column", display: "flex" }}>
                  <Button
                    variant="contained"
                    sx={{
                      texttransform: "none",
                      background: "green",
                      "&:hover": {
                        background:
                          "linear-gradient(45deg, #4EAF26, #6EBB2D, #8FC833)",
                      },
                    }}
                    onClick={() => {
                      handleAccept();
                    }}
                  >
                    Yes
                    <TiTick
                      style={{
                        fontSize: "1.2rem",
                        marginLeft: "5px",
                      }}
                    />
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ mt: 2 }}
                    onClick={() => openConfirm(false)}
                  >
                    No
                    <AiOutlineClose
                      style={{
                        fontSize: "1.2rem",
                        marginLeft: "5px",
                      }}
                    />
                  </Button>
                </Box>
              </div>
            </Modal>
          )}
        </>
      }
    </>
  );
};

export default SuperAdmins;
