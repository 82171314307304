import {
  BiCategoryAlt,
} from "react-icons/bi";
import { TbBrandProducthunt } from "react-icons/tb";
import { BsListOl } from "react-icons/bs";
import { BiDonateHeart } from "react-icons/bi";
import { CiInboxIn, CiFlag1, CiShoppingTag } from "react-icons/ci";
import { TbReportAnalytics } from "react-icons/tb";
import { IoStorefrontOutline } from "react-icons/io5";
import { Location } from "react-router-dom";
import { ISideBarOption } from "../interfaces";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { PiUsersThreeBold } from "react-icons/pi";
import { FaUserTie } from "react-icons/fa";
import { FaUserSecret } from "react-icons/fa";
import { CiCircleList } from "react-icons/ci";

export const sideBarOptions: ISideBarOption[] = [
  // { title: "Dashboard", icon: BiHomeAlt, url: "/dashboard", name: "dashboard" },
  {
    title: "Products",
    icon: TbBrandProducthunt,
    url: "/product",
    name: "product",
  },
  {
    title: "Regenf Products",
    icon: MdOutlinePhoneAndroid,
    url: "/regenf/product",
    name: "regenfProduct",
  },
  {
    title: "Donated Products",
    icon: BiDonateHeart,
    url: "/donated/product",
    name: "donatedProduct",
  },
  { title: "Listing", icon: BsListOl, url: "/listing", name: "listing" },
  {
    title: "Donated Listing",
    icon: CiCircleList,
    url: "/donated-listing",
    name: "donated_listing",
  },
  { title: "Inbound", icon: CiInboxIn, url: "/inbound", name: "inbound" },
  {
    title: "Regenf Stores",
    icon: IoStorefrontOutline,
    url: "/regenfstores",
    name: "regenfstores",
  },
  {
    title: "Category",
    icon: BiCategoryAlt,
    url: "/category",
    name: "category",
  },
  { title: "Brands", icon: CiShoppingTag, url: "/brands", name: "brands" },
  // {
  //   title: "Vehicles",
  //   icon: IoCarSportOutline,
  //   url: "/vehicles",
  //   name: "vehicles",
  // },
  {
    title: "Super Admins",
    icon: FaUserSecret,
    url: "/super-admins",
    name: "super_admins",
  },
  { title: "Admins", icon: FaUserTie, url: "/admins", name: "admins" },
  { title: "Users", icon: PiUsersThreeBold, url: "/users", name: "users" },
  { title: "Banner", icon: CiFlag1, url: "/banner", name: "banner" },
  {
    title: "Reports",
    icon: TbReportAnalytics,
    url: "/reports",
    name: "reports",
  },
];

export const superAdminMenuItems = [
  "product",
  "regenfProduct",
  "donatedProduct",
  "category",
  "listing",
  "inbound",
  "brands",
  "regenfstores",
  "banner",
  "reports",
  "users",
  "admins",
  "super_admins",
  "donated_listing",
];

export const adminMenuItems = [
  "product",
  "regenfProduct",
  "donatedProduct",
  "category",
  "listing",
  "inbound",
  "brands",
  "regenfstores",
  "banner",
  "reports",
  "donated_listing",
];

export const getTitle = (location: Location) =>
  sideBarOptions.find((d) =>
    location.pathname.toLowerCase().includes(d.url.toLowerCase())
  )?.title || "dashboard";

export const ROLES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
};

export const ROLE_TYPES = {
  super_admin: "Super Admin",
  admin: "Admin",
};

export const SUPER_ADMIN_ROLE = "super_admin";

export const ADMIN_ROLE = "admin";

export const USER_ROLE = "user";

export const RECORD_PERMISSIONS_TYPES = {
  VIEW: "view",
  EDIT: "edit",
  OWNER: "owner",
};

export const RECORD_PERMISSIONS = [
  RECORD_PERMISSIONS_TYPES.VIEW,
  RECORD_PERMISSIONS_TYPES.EDIT,
];

export const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "svg"];
