import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse} from "../interfaces";

interface ProductService {
    getReport: (data: {}) => Promise<IAPIResponse>;
}

const productService = (httpClient: AxiosInstance): ProductService => {
    return {
        getReport: async (data): Promise<IAPIResponse> => {
            try {
                const res: AxiosResponse<IAPIResponse> = await httpClient.post(
                    '/get/regenf/report/',
                    { dates: data }
                );
                return res.data;
            } catch (error) {
                return { status: false, message: "Report fetching failed" };
            }
        },
    };
};

export default productService;
