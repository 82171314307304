import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, IUser } from "../interfaces";

interface ProductService {
  createUser: (data: {}) => Promise<IAPIResponse>;
  addBannerService: (data: {}) => Promise<IAPIResponse<any | null>>;
  updateUser: (data: {}, id: string) => Promise<IAPIResponse>;
  getUsers: () => Promise<IAPIResponse<IUser[]>>;
  getAllUsers: () => Promise<IAPIResponse<IUser[]>>;
  // getAdmins: () => Promise<IAPIResponse<IUser[]>>;
  getSuperAdmins: () => Promise<IAPIResponse<IUser[]>>;
  deleteUser: (id: string) => Promise<IAPIResponse>;
  getSingle: (id: string) => Promise<IAPIResponse<IUser | null>>;
  getBannerService: () => Promise<IAPIResponse<any | null>>;
  
}

const productService = (httpClient: AxiosInstance): ProductService => {
  return {
    addBannerService: async (data): Promise<IAPIResponse<any | null>> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/add/new/banner/",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Banner change failed" };
      }
    },
    createUser: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/user/create",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User creation failed" };
      }
    },
    getUsers: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/users"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    getAllUsers: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/all-users"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    getSuperAdmins: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/super-admins"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    deleteUser: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/user/delete/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User deletion failed" };
      }
    },
    getSingle: async (id): Promise<IAPIResponse<IUser | null>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser | null>> =
          await httpClient.get(`/user/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch user" };
      }
    },
    updateUser: async (data, id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/user/update/${id}`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User update failed" };
      }
    },
    getBannerService: async (): Promise<IAPIResponse<any | null>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any | null>> =
          await httpClient.get("/get/home/banner/admin/");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch user" };
      }
    },
  };
};

export default productService;
