import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, ICategoryList } from "../interfaces";

interface ProductService {
  editCategory: (id: string, data: {}) => Promise<IAPIResponse>;
  deleteCategory: (id: string) => Promise<IAPIResponse>;
  addCategory: (data: {}) => Promise<IAPIResponse>;
  getAllCategoryList: () => Promise<IAPIResponse<ICategoryList[]>>;
  getCategoryTypes: () => Promise<IAPIResponse<any[]>>;
  listCategories: (
    page: number,
    limit: number,
    search: string
  ) => Promise<IAPIResponse<any[]>>;
  getSingle: (id: string) => Promise<IAPIResponse<any | null>>;
  addThumbail: (data: {}) => Promise<IAPIResponse<any | null>>;
}

const categoryService = (httpClient: AxiosInstance): ProductService => {
  return {
    getAllCategoryList: async (): Promise<IAPIResponse<ICategoryList[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any[]>> = await httpClient.get(
          "/get/all/category/admin"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch catgories" };
      }
    },
    listCategories: async (
      page,
      limit,
      search = ""
    ): Promise<IAPIResponse<any[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any[]>> = await httpClient.get(
          `/list/all/category/admin?page=${page}&limit=${limit}&search=${search}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch catgories" };
      }
    },
    getSingle: async (id): Promise<IAPIResponse<any | null>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any | null>> =
          await httpClient.post("/get/category/admin/", { categoryId: id });
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch user" };
      }
    },
    editCategory: async (id, data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/edit/category/admin/",
          {
            categoryId: id,
            data,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Category update failed" };
      }
    },
    addCategory: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/category/add",
          {
            data,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Category update failed" };
      }
    },
    addThumbail: async (data) => {
      try {
        const res = await httpClient.post("/add/category/image/", data);
        return res.data;
      } catch (err) {
        console.log("err", err);
        return { status: false, message: "File upload failed" };
      }
    },
    getCategoryTypes: async (): Promise<IAPIResponse<any[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<any[]>> = await httpClient.get(
          "/get/category/types/admin"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch catgories" };
      }
    },
    deleteCategory: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/remove/category/admin",
          {
            categoryId: id,
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Category deletion failed" };
      }
    },
  };
};

export default categoryService;
