import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import TopBar from "./TopBar";
import logo from "../../images/regenf_logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
// import { useSnackbar } from "notistack";
import { getSideBarOptions } from "../../config/app-config";

const drawerWidth = 245;

interface Props {
  children?: React.ReactNode;
}

const ResponsiveDrawer: React.FC<Props> = (props) => {
  const { children } = props;
  // const { id } = useParams();
  // const { enqueueSnackbar: snackbar } = useSnackbar();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const locationObj = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const sideBarOptions = getSideBarOptions();
 

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getBackground = (path: string) =>
    locationObj.pathname.includes(path)
      ? { background: "#EAEEF6", color: theme.colors?.primary.default }
      : { background: "#F8F9FB", color: "#0E1C3F" };

  // const style = {
  //   position: "absolute" as "absolute",
  //   top: 142,
  //   left: 32,
  //   width: 190,
  //   bgcolor: "background.paper",
  //   boxShadow: "24",
  //   borderRadius: `0px 0px 12px 12px`,
  // };

  const drawer = (
    <Box
      className="full-height"
      sx={{
        backgroundColor: theme.colors?.colorFive.default,
      }}
    >
      <Toolbar
        sx={{
          height: 73,
        }}
      >
        <Box>
          <img style={{ width: 146, marginTop: 2 }} src={logo} alt="" />
        </Box>
      </Toolbar>
      <Divider />

      <List
        sx={{
          backgroundColor: theme.colors?.colorFive.default,
        }}
      >
        {sideBarOptions.map((d, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ ...getBackground(d.url.toLowerCase()) }}
          >
            <ListItemButton
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                height: "48px",
                pl: 3,
                pt: 3.3,
                pb: 3.3,
              }}
              onClick={() => navigate(d.url)}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  minWidth: "20px",
                }}
              >
                {
                  <d.icon
                    fontSize={18}
                    color={getBackground(d.url.toLowerCase()).color}
                  />
                }
              </ListItemIcon>
              <ListItemText
                primary={d.title}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontWeight: 400,
                    fontSize: 16,
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }} className="full-height">
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: "none",
          background: "white",
          height: 75,
        }}
      >
        <TopBar />
        <Divider />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
              backgroundColor: "#F8F9FB",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 3,
          pl: 4,
          paddingRight: "10px",
          pr: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar sx={{ minHeight: `48px !important` }} />
        {children}
      </Box>
    </Box>
  );
};

export default ResponsiveDrawer;
