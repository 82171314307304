/* eslint-disable react-hooks/exhaustive-deps */
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { apiConfig } from "../config/api-config";
import Template from "../components/template/Template";
import Admins from "../pages/admins/Admins";
import SuperAdmins from "../pages/super-admins/superAdmins";

const UserSignIn = lazy(() => import("../pages/sign-in/SignIn"));
const FourNotFour = lazy(() => import("../pages/FourNotFour"));
const Product = lazy(() => import("../pages/product/Product"));
const RegenfProduct = lazy(
  () => import("../pages/regenf-products/RegenfProducts")
);
const DonatedProduct = lazy(
  () => import("../pages/donated-products/DonatedProduct")
);
const Category = lazy(() => import("../pages/category/Category"));
const Listing = lazy(() => import("../pages/listing/Listing"));
const DonatedListing = lazy(
  () => import("../pages/donated-listing/DonatedListing")
);
const Inbound = lazy(() => import("../pages/inbound/Inbount"));
const Brands = lazy(() => import("../pages/brand/Brands"));
const Vehicles = lazy(() => import("../pages/vehicles/Vehicles"));
const RegenfStores = lazy(() => import("../pages/regenfstores/RegenfStores"));
const Banner = lazy(() => import("../pages/banner/Banner"));
const Reports = lazy(() => import("../pages/reports/Reports"));
const Users = lazy(() => import("../pages/users/Users"));
const UserDetails = lazy(() => import("../pages/user-details/UserDetails"));

const Routers = () => {
   return (
    <>
      <Routes>
        <Route
          path={`${apiConfig.basePath}/sign-in`}
          element={<UserSignIn />}
        />
        <Route
          path={`${apiConfig.basePath}/`}
          element={
            <Template>
              <></>
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}`}
          element={
            <Template>
              <></>
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/product`}
          element={
            <Template>
              <Product />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/regenf/product`}
          element={
            <Template>
              <RegenfProduct />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/donated/product`}
          element={
            <Template>
              <DonatedProduct />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/category`}
          element={
            <Template>
              <Category />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/listing`}
          element={
            <Template>
              <Listing />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/donated-listing`}
          element={
            <Template>
              <DonatedListing />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/inbound`}
          element={
            <Template>
              <Inbound />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/brands`}
          element={
            <Template>
              <Brands />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/vehicles`}
          element={
            <Template>
              <Vehicles />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/regenfstores`}
          element={
            <Template>
              <RegenfStores />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/banner`}
          element={
            <Template>
              <Banner />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/reports`}
          element={
            <Template>
              <Reports />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/users`}
          element={
            <Template>
              <Users />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/users/:id`}
          element={
            <Template>
              <UserDetails />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/admins/`}
          element={
            <Template>
              <Admins />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/super-admins`}
          element={
            <Template>
              <SuperAdmins />
            </Template>
          }
        />
        <Route path="*" element={<FourNotFour />} />
      </Routes>
    </>
  );
};

export default Routers;
